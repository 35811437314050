import { useParams } from 'react-router-dom'
import { useLearningModules } from 'src/shared/hooks/learningModules'
import { useProgram } from 'src/shared/hooks/program'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import { convertFetchedToLMS } from 'src/utils/learning'
import Literacy from '../views/Literacy'

interface IParams {
  id: string
}
const LiteracyContainer = () => {
  const { id } = useParams<IParams>()

  const { isLoading, program } = useProgram(id)
  const { learningModules, isLoading: learningModulesLoading } =
    useLearningModules()

  if (!isLoading && !learningModulesLoading && program)
    return (
      <Literacy
        programDuration={0}
        learningModules={
          learningModules?.map((module) => convertFetchedToLMS(module)) || []
        }
      />
    )

  return <LoadingScreen />
}

export default LiteracyContainer
