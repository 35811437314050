import React from 'react'
import { Controller } from 'react-hook-form'

import { useIndicationSearch } from 'src/shared/hooks/drugLibrary'
import { useIndicationSearchForm } from 'src/features/tools/hooks/forms'
import { Tools } from 'src/shared/styled'
import { COLORS } from 'src/shared/theme'
import Table from 'src/shared/views/Table/Table'
import { BoldHeading, SubHeaderName } from 'src/shared/styled/Text'
import { ButtonWithMargin, ButtonWithWidth } from 'src/shared/styled/Buttons'
import TextField from 'src/shared/views/TextField'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import ToolsModal from 'src/features/tools/views/ToolsModal'
import { Create } from '@material-ui/icons'

const { HeaderContainer, SearchContainer, Form, ViewModalLink } = Tools

const formFieldStyles = {
  marginBottom: '30px',
  marginRight: '20px',
  minWidth: '200px',
  width: '50%',
  maxWidth: '250px'
}

const Indications = () => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty }
  } = useIndicationSearchForm()

  const [modalState, setModalState] = React.useState({
    isOpen: false,
    indication: ''
  })

  const [enableQuery, setEnableQuery] = React.useState(false)
  const [queryValue, setQueryValue] = React.useState('')

  const { indications, isLoading, isSuccess } = useIndicationSearch(
    queryValue,
    10,
    enableQuery
  )

  React.useEffect(() => {
    if (isSuccess) {
      setEnableQuery(false)
    }
  }, [isSuccess])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Indication',
        accessor: 'name'
      },
      {
        Header: 'Associated Dins',
        accessor: 'dins',
        Cell: ({ row }: any): Array<any> => {
          if (!row.original.dins) {
            return []
          }
          return row.original.dins.map((drug: any) => drug.din).join(', ')
        }
      },
      {
        Header: 'Details',
        accessor: 'details',
        Cell: ({ row }: any) => (
          <ViewModalLink
            onClick={() =>
              setModalState({ isOpen: true, indication: row.original.id })
            }
          >
            <Create />
          </ViewModalLink>
        ),
        disableSortBy: true,
        width: 60
      }
    ],
    []
  )

  const onSubmit = (formValues: any) => {
    setQueryValue(formValues.indication)
    setEnableQuery(true)
  }

  const resetForm = () => {
    setQueryValue('')
    setEnableQuery(false)
    reset()
  }
  watch()

  return (
    <div>
      <HeaderContainer>
        <SubHeaderName>Indications</SubHeaderName>
        <ButtonWithMargin
          variant="contained"
          color="primary"
          onClick={() => setModalState({ ...modalState, isOpen: true })}
        >
          ADD NEW INDICATION
        </ButtonWithMargin>
      </HeaderContainer>
      <SearchContainer>
        <BoldHeading>Indication Search</BoldHeading>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            defaultValue={''}
            render={({ field: { ref, ...props } }) => (
              <TextField
                style={formFieldStyles}
                id="indication"
                label="Indication"
                placeholder="Enter an Indication"
                inputRef={ref}
                error={!!errors?.indication?.message}
                helperText={errors?.indication?.message}
                {...props}
              />
            )}
            name="indication"
            control={control}
          />
          <div>
            <ButtonWithWidth
              disabled={!isDirty}
              type="submit"
              color="secondary"
            >
              SEARCH
            </ButtonWithWidth>
            <ButtonWithWidth
              type="button"
              variant="outlined"
              onClick={resetForm}
            >
              RESET
            </ButtonWithWidth>
          </div>
        </Form>
      </SearchContainer>
      {isLoading ? (
        <LoadingScreen />
      ) : indications ? (
        <Table
          columns={columns}
          data={indications}
          emptyMessage="No indications match your search"
          placeholder="Search Indications"
          getHeaderProps={() => ({
            style: {
              background: COLORS.white
            }
          })}
        />
      ) : null}
      <ToolsModal
        isOpen={modalState.isOpen}
        handleClose={() => setModalState({ indication: '', isOpen: false })}
        id={modalState.indication}
        type="indication"
      />
    </div>
  )
}

export default Indications
