import React from 'react'
import { useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { FETCH_ALL_LEARNING_MODULES } from 'src/constants/queries'
import {
  ILMS,
  ILMSImageSection,
  ILMSOption,
  ILMSPage,
  ILMSProvider,
  ILMSQuestionSection,
  ILMSTextSection,
  LMSSection,
  LMSStatus
} from 'src/models/learning'
import {
  useDeleteLearningModule,
  useDraftLearningModule,
  useLearningModule,
  usePublishLearningModule,
  useSaveLearningModule
} from 'src/shared/hooks/learningModules'
import { useImmerReducer } from 'use-immer'
import { v4 as uuid } from 'uuid'
import { isEqual, omit } from 'lodash'
import { convertFetchedToLMS } from 'src/utils/learning'

interface RouteParams {
  id: string
}

type IAction =
  | {
      type: 'initialize'
      payload: ILMS
    }
  | {
      type: 'add-page'
      payload: { currentPageId?: string }
    }
  | {
      type: 'save-page-title'
      payload: { id: string; title: string; description: string }
    }
  | {
      type: 'save-section'
      payload: {
        pageId: string
        sectionId: string
        section: any
      }
    }
  | {
      type: 'add-section'
      payload: {
        pageId: string
        sectionType: 'question' | 'text' | 'image'
      }
    }
  | {
      type: 'remove-section'
      payload: {
        pageId: string
        sectionId: string
      }
    }
  | {
      type: 'remove-title'
      payload: {
        pageId: string
      }
    }
  | {
      type: 'save-lms-details'
      payload: {
        isDirty: boolean
        formValues: {
          title: string
          description: string
          duration: string
          coverImageUrl: string
        }
      }
    }
  | {
      type: 'set-saved-false'
      payload: {
        pageId?: string
        sectionId?: string
      }
    }
  | {
      type: 'delete-page'
      payload: { pageId: string }
    }
  | {
      type: 'set-draft-status'
    }
  | {
      type: 'set-publish-status'
    }

interface LMSContext {
  state: ILMS
  addPage: (currentPageId?: string) => void
  savePageTitle: (id: string, formValues: any) => void
  isInitialized: boolean
  saveSection: (pageId: string, sectionId: string, section: any) => void
  addSection: (
    sectionType: 'question' | 'text' | 'image',
    pageId: string
  ) => void
  removeSection: (pageId: string, sectionId: string) => void
  removeTitle: (pageId: string) => void
  saveLMSDetails: (formValues: any, isDirty: boolean) => void
  saveLearningModule: () => void
  deleteLearningModule: () => void
  setSavedFalse: (pageId?: string, sectionId?: string) => void
  deletePage: (pageId: string) => void
  updateStatus: (status: LMSStatus) => Promise<void>
  resetChanges: () => void
  setResetFlag: () => void
  isQuestionEmpty: (question: ILMSQuestionSection) => boolean
  uploadLearningModule: (learningModule: ILMS) => void
  isReset: boolean
  saveLoading: boolean
  deleteLoading: boolean
  publishLoading: boolean
  draftLoading: boolean
  isCreate: boolean
}

const emptyTextSection: ILMSTextSection = {
  id: '',
  text: '',
  saved: false,
  type: 'TextSection'
}

const emptyImageSection: ILMSImageSection = {
  id: '',
  imageUrl: '',
  imageDescription: '',
  saved: false,
  type: 'ImageSection'
}

const emptyQuestion: ILMSQuestionSection = {
  id: '',
  type: 'QuestionSection',
  question: {
    type: 'MultipleChoice',
    questionText: '',
    answerOptions: [
      {
        correct: true,
        text: '',
        weight: 1,
        indexLabel: 'a)'
      },
      {
        correct: true,
        text: '',
        weight: 1,
        indexLabel: 'b)'
      },
      {
        correct: true,
        text: '',
        weight: 1,
        indexLabel: 'c)'
      },
      {
        correct: true,
        text: '',
        weight: 1,
        indexLabel: 'd)'
      }
    ],
    correctAnswerFeedback: '',
    incorrectAnswerFeedback: ''
  },
  saved: false
}

const emptyPage: ILMSPage = {
  id: '',
  header: '',
  description: '',
  sections: [],
  saved: true
}

const initialState: ILMS = {
  id: '',
  title: '',
  description: '',
  coverImageUrl: '',
  estimatedDuration: '0',
  isActive: false,
  pages: [],
  saved: true,
  status: LMSStatus.DRAFT
}

const formatQuestionFormValues = (
  formValues: any,
  sectionId: string,
  questionId?: string,
  questionType?: string
) => {
  console.log('formValues', formValues)
  const values: ILMSQuestionSection = {
    id: sectionId,
    type: 'QuestionSection',
    question: {
      type: 'SingleChoice',
      questionText: formValues.question,
      answerOptions: [],
      correctAnswerFeedback: formValues.correctFeedback,
      incorrectAnswerFeedback: formValues.incorrectFeedback
    },

    saved: false
  }

  let correctAnswerCount = 0
  for (let i = 0; i < formValues.answerOptions.length; i++) {
    const answerOptions = formValues.answerOptions[i]
    const option: ILMSOption = {
      correct: answerOptions.correct,
      text: answerOptions.text,
      weight: answerOptions.weight,
      // Get corresponding letter to index (0 === a, 1 === b, etc.)
      indexLabel: String.fromCharCode(97 + i) + ')'
    }

    if (answerOptions.correct) correctAnswerCount++

    values.question.answerOptions.push(option)
  }
  if (correctAnswerCount > 1) {
    values.question.type = 'MultipleChoice'
  }

  if (questionType) values.question.type = questionType
  if (questionId) values.question.id = questionId

  return values
}

const validateLearningModule = (learningModule: ILMS) => {
  if (learningModule.pages.length > 0) {
    const populatedPages = learningModule.pages as ILMSPage[]
    const unsavedPages = populatedPages.filter((page: ILMSPage) => {
      const unsavedSection = page.sections.filter(
        (section: ILMSImageSection | ILMSTextSection | ILMSQuestionSection) =>
          !section.saved
      )

      return unsavedSection.length > 0 || !page.saved
    })

    if (unsavedPages.length > 0) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}

// remove any before push
const LMSContext = React.createContext<LMSContext | undefined>(undefined)

const LMSReducer = (draft: ILMS, action: IAction) => {
  switch (action.type) {
    case 'initialize': {
      const { payload: learningModule } = action
      // Add the "saved" field each of the pages and their sections
      learningModule.saved = true
      learningModule.pages = learningModule.pages.map((page: ILMSPage) => {
        page.saved = true
        const populatedSections = page.sections as (
          | ILMSImageSection
          | ILMSTextSection
          | ILMSQuestionSection
        )[]
        const sectionsCopy = populatedSections.map(
          (
            section: ILMSImageSection | ILMSTextSection | ILMSQuestionSection
          ) => {
            return {
              ...section,
              saved: true
            }
          }
        )
        return {
          ...page,
          saved: true,
          sections: sectionsCopy
        }
      })
      draft = learningModule
      return draft
    }
    case 'add-page': {
      const { currentPageId } = action.payload
      if (currentPageId) {
        const currentPageIndex = draft.pages.findIndex(
          (page: ILMSPage) => currentPageId === page.id
        )
        draft.pages.splice(currentPageIndex + 1, 0, {
          ...emptyPage,
          id: uuid()
        })
      } else {
        draft.pages.splice(0, 0, { ...emptyPage, id: uuid() })
      }

      draft.saved = false

      return draft
    }
    case 'delete-page': {
      const { pageId } = action.payload

      const pageIndex: number = draft.pages.findIndex(
        (page: ILMSPage) => page.id === pageId
      )
      draft.pages.splice(pageIndex, 1)

      draft.saved = false

      return draft
    }
    case 'save-page-title': {
      const { id, title, description } = action.payload
      const pageIndex = draft.pages.findIndex(
        (page: ILMSPage) => page.id === id
      )
      draft.pages[pageIndex].header = title
      draft.pages[pageIndex].description = description
      draft.pages[pageIndex].saved = true

      draft.saved = false
      return draft
    }
    case 'save-section': {
      const { pageId, sectionId, section } = action.payload
      const pageIndex: number = draft.pages.findIndex(
        (page: ILMSPage) => page.id === pageId
      )
      const sectionIndex: number = draft.pages[pageIndex].sections.findIndex(
        (section: LMSSection) => section.id === sectionId
      )

      draft.pages[pageIndex].sections[sectionIndex] = {
        ...draft.pages[pageIndex].sections[sectionIndex],
        ...section,
        saved: true
      }

      draft.saved = false
      return draft
    }
    case 'add-section': {
      const { pageId, sectionType } = action.payload
      const pageIndex: number = draft.pages.findIndex(
        (page: ILMSPage) => page.id === pageId
      )
      const newSection =
        sectionType === 'text'
          ? { ...emptyTextSection, id: uuid() }
          : sectionType === 'question'
          ? { ...emptyQuestion, id: uuid() }
          : { ...emptyImageSection, id: uuid() }

      draft.pages[pageIndex].sections = [
        ...draft.pages[pageIndex].sections,
        newSection
      ]

      draft.saved = false
      return draft
    }
    case 'remove-section': {
      const { pageId, sectionId } = action.payload
      const pageIndex: number = draft.pages.findIndex(
        (page: ILMSPage) => page.id === pageId
      )
      const sectionIndex: number = draft.pages[pageIndex].sections.findIndex(
        (section: LMSSection) => section.id === sectionId
      )

      draft.pages[pageIndex].sections.splice(sectionIndex, 1)

      draft.saved = false
      return draft
    }
    case 'remove-title': {
      const { pageId } = action.payload
      const pageIndex: number = draft.pages.findIndex(
        (page: ILMSPage) => page.id === pageId
      )
      draft.pages[pageIndex].header = ''
      draft.pages[pageIndex].description = ''

      draft.saved = false
      return draft
    }
    case 'save-lms-details': {
      const { formValues, isDirty } = action.payload

      draft.title = formValues.title
      draft.estimatedDuration = formValues.duration
      draft.description = formValues.description
      draft.coverImageUrl = formValues.coverImageUrl

      if (isDirty) {
        draft.saved = false
      }
      return draft
    }
    case 'set-saved-false': {
      const { pageId, sectionId } = action.payload
      if (pageId && sectionId) {
        const pageIndex: number = draft.pages.findIndex(
          (page: ILMSPage) => page.id === pageId
        )
        const sectionIndex: number = draft.pages[pageIndex].sections.findIndex(
          (section: LMSSection) => section.id === sectionId
        )

        draft.pages[pageIndex].sections[sectionIndex].saved = false
      } else if (pageId && !sectionId) {
        const pageIndex: number = draft.pages.findIndex(
          (page: ILMSPage) => page.id === pageId
        )
        draft.pages[pageIndex].saved = false
      }
      return draft
    }
    case 'set-draft-status': {
      return {
        ...draft,
        status: LMSStatus.DRAFT
      }
    }
    case 'set-publish-status': {
      return {
        ...draft,
        status: LMSStatus.PUBLISHED
      }
    }
  }
}

const LMSProvider = ({ children }: ILMSProvider) => {
  const [state, dispatch] = useImmerReducer(LMSReducer, {
    ...initialState,
    id: uuid()
  })
  const [isInitialized, setIsInitialized] = React.useState(false)
  const [isReset, setIsReset] = React.useState(false)
  const { id } = useParams<RouteParams>()
  const { learningModule, isLoading } = useLearningModule(id ?? '')
  const queryClient = useQueryClient()
  const { save, isLoading: saveLoading } = useSaveLearningModule()
  const { deleteModule, isLoading: deleteLoading } = useDeleteLearningModule()
  const {
    draftModule,
    isLoading: draftLoading,
    isSuccess: draftSuccess
  } = useDraftLearningModule()
  const {
    publishModule,
    isLoading: publishLoading,
    isSuccess: publishSuccess
  } = usePublishLearningModule()
  const history = useHistory()

  React.useEffect(() => {
    if (!isLoading && !!learningModule) {
      dispatch({
        type: 'initialize',
        payload: convertFetchedToLMS(learningModule)
      })
      setIsInitialized(true)
    } else if (!isLoading && !learningModule) {
      setIsInitialized(true)
    }
  }, [learningModule, isLoading, dispatch])

  const addPage = (currentPageId?: string) =>
    dispatch({ type: 'add-page', payload: { currentPageId } })
  const savePageTitle = (id: string, formValues: any) =>
    dispatch({
      type: 'save-page-title',
      payload: { id, ...formValues }
    })
  const saveSection = (pageId: string, sectionId: string, section: any) => {
    dispatch({
      type: 'save-section',
      payload: { pageId, sectionId, section }
    })
  }
  const addSection = (
    sectionType: 'question' | 'text' | 'image',
    pageId: string
  ) => dispatch({ type: 'add-section', payload: { pageId, sectionType } })

  const removeSection = (pageId: string, sectionId: string) =>
    dispatch({ type: 'remove-section', payload: { pageId, sectionId } })

  const removeTitle = (pageId: string) => {
    dispatch({ type: 'remove-title', payload: { pageId } })
  }
  const saveLMSDetails = (formValues: any, isDirty: boolean) => {
    dispatch({ type: 'save-lms-details', payload: { formValues, isDirty } })
  }
  const setSavedFalse = (pageId?: string, sectionId?: string) => {
    dispatch({ type: 'set-saved-false', payload: { pageId, sectionId } })
  }
  const deletePage = (pageId: string) => {
    dispatch({ type: 'delete-page', payload: { pageId } })
  }
  const resetChanges = () => {
    dispatch({
      type: 'initialize',
      payload: learningModule
        ? convertFetchedToLMS(learningModule)
        : { ...initialState }
    })
    setIsReset(true)
  }
  const uploadLearningModule = (learningModule: ILMS) => {
    dispatch({
      type: 'initialize',
      payload: learningModule
    })
  }

  const saveLearningModule = async () => {
    if (validateLearningModule(state)) {
      await save({
        learningModule: state,
        id
      })
      queryClient.invalidateQueries(FETCH_ALL_LEARNING_MODULES)

      history.push('/learning')
    }
  }

  const updateStatus = async (status: LMSStatus) => {
    if (state.status !== status) {
      switch (status) {
        case LMSStatus.DRAFT:
          await draftModule({
            id
          })
          break
        case LMSStatus.PUBLISHED:
          await publishModule({
            id
          })
          break
        default:
          break
      }
    }
  }

  const setResetFlag = () => {
    setIsReset(false)
  }

  const isQuestionEmpty = (question: ILMSQuestionSection) =>
    isEqual(omit(emptyQuestion, ['id']), omit(question, ['id']))

  const deleteLearningModule = async () => {
    await deleteModule({ id })
    queryClient.invalidateQueries(FETCH_ALL_LEARNING_MODULES)
    history.push('/learning')
  }

  React.useEffect(() => {
    if (!draftLoading && draftSuccess) {
      dispatch({
        type: 'set-draft-status'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftLoading, draftSuccess])

  React.useEffect(() => {
    if (!publishLoading && publishSuccess) {
      dispatch({
        type: 'set-publish-status'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishLoading, publishSuccess])

  return (
    <LMSContext.Provider
      value={{
        state,
        addPage,
        savePageTitle,
        saveSection,
        addSection,
        removeSection,
        removeTitle,
        saveLMSDetails,
        setSavedFalse,
        saveLearningModule,
        deleteLearningModule,
        deletePage,
        updateStatus,
        resetChanges,
        isReset,
        setResetFlag,
        isInitialized,
        saveLoading,
        deleteLoading,
        uploadLearningModule,
        isQuestionEmpty,
        publishLoading,
        draftLoading,
        isCreate: !id
      }}
    >
      {children}
    </LMSContext.Provider>
  )
}

const useLMS = () => {
  const context = React.useContext(LMSContext)

  if (context === undefined) {
    throw new Error(
      'useLMS can only be used in a component wrapped by LMSProvider'
    )
  }

  return context
}

export { LMSProvider, useLMS, formatQuestionFormValues }
