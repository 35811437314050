import { FC, FormEvent, useState } from 'react'
import styled from 'styled-components'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'

import TextField from 'src/shared/views/TextField'
import { SmallButton } from 'src/shared/styled/Buttons'
import {
  formatDrugFromLibrary,
  combineDrugIngredientsIntoValue
} from 'src/utils/drugLibrary'
import { DrugSearchResult } from 'src/features/userAdmin/views/DrugSearchResult'
import { COLORS } from 'src/shared/theme'
import { IDrugLibraryItem } from 'src/models/drugLibrary'
import { useDrugSearch } from 'src/shared/hooks/drugLibrary'

const SearchSection = styled.div`
  margin-bottom: 30px;
  flex-direction: row;
  display: flex;
`

const SearchFields = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`

const SearchResults = styled.div`
  margin-left: 20px;
  margin-bottom: 30px;
  flex: 5;
  height: 347px;
  overflow: scroll;
  border: 1px solid ${COLORS.borderGray};
  border-radius: 8px;
`

const SelectedDrug = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 10px;
  border-bottom: 1px solid ${COLORS.borderGray};
  height: 100px;
  .drug-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  p {
    margin: 0;
    padding: 0;
  }
  & .close {
    margin-left 20px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100px;
    & svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
`

const SearchField = styled(TextField)`
  margin: 0 0 20px 0;
`

const EmptyStateText = styled.p`
  text-align: center;
`

interface IDrugSearch {
  onItemSelected: (drug: IDrugLibraryItem) => void
  selectedDins: string[]
  showSelectedDins?: boolean
  dinsToDisplay?: IDrugLibraryItem[]
  onRemoveItem?: (drug: IDrugLibraryItem) => void
}

const DrugSearch: FC<IDrugSearch> = ({
  onItemSelected,
  selectedDins,
  showSelectedDins,
  dinsToDisplay,
  onRemoveItem
}) => {
  const [medicationNameSearch, setMedicationNameSearch] = useState('')
  const [ingredientSearch, setIngredientSearch] = useState('')
  const [dinSearch, setDinSearch] = useState('')
  const [manufacturerSearch, setManufacturerSearch] = useState('')

  const [dinError, setDinError] = useState(false)

  const { dins, isFetching, isLoading, error, refetch } = useDrugSearch(
    {
      activeIngredient: ingredientSearch,
      brandName: medicationNameSearch,
      din: dinSearch,
      manufacturer: manufacturerSearch
    },
    undefined,
    {
      staleTime: 0,
      cacheTime: 0
    }
  )

  const onSearch = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setDinError(false)
    if (!!dinSearch && dinSearch.length !== 8) {
      return setDinError(true)
    }
    refetch()
  }

  return (
    <form>
      <SearchSection>
        <SearchFields>
          <>
            <SearchField
              InputLabelProps={{ shrink: true }}
              label="Search Medication Name"
              value={medicationNameSearch}
              onChange={(e) => setMedicationNameSearch(e.target.value)}
            />
            <SearchField
              InputLabelProps={{ shrink: true }}
              label="Search DIN"
              error={dinError}
              helperText={dinError && 'DIN must be 8 numbers'}
              value={dinSearch}
              type="number"
              onChange={(e) => setDinSearch(e.target.value)}
            />
            <SearchField
              InputLabelProps={{ shrink: true }}
              label="Search Ingredients"
              value={ingredientSearch}
              onChange={(e) => setIngredientSearch(e.target.value)}
            />
            <SearchField
              InputLabelProps={{ shrink: true }}
              label="Search Manufacturer"
              value={manufacturerSearch}
              onChange={(e) => setManufacturerSearch(e.target.value)}
            />
            <SmallButton
              isLoading={isLoading || isFetching}
              onClick={onSearch}
              type="submit"
              disabled={
                !(
                  medicationNameSearch ||
                  ingredientSearch ||
                  dinSearch ||
                  manufacturerSearch
                )
              }
            >
              Search
            </SmallButton>
            {error && (
              <EmptyStateText>
                An error occurred searching medications
              </EmptyStateText>
            )}
          </>
        </SearchFields>
        <SearchResults>
          {dins ? (
            dins.length > 0 ? (
              dins.map((d, i) => {
                const drug = formatDrugFromLibrary(d)
                const isSelected = !!selectedDins.find(
                  (din) => drug.din.value === din
                )

                return (
                  <DrugSearchResult
                    key={`${drug.id}-${i}`}
                    onClick={() => {
                      if (isSelected && onRemoveItem) onRemoveItem(drug)
                      else onItemSelected(drug)
                    }}
                    drug={drug}
                    isSelected={isSelected}
                  />
                )
              })
            ) : (
              <EmptyStateText>No results found</EmptyStateText>
            )
          ) : (
            <EmptyStateText>
              Your search results will show up here
            </EmptyStateText>
          )}
        </SearchResults>
        {showSelectedDins ? (
          <SearchResults>
            {onRemoveItem && dinsToDisplay && dinsToDisplay.length > 0 ? (
              dinsToDisplay?.map((drug) => {
                return (
                  <SelectedDrug key={`${drug.din.value}`}>
                    <div className="drug-info">
                      <strong>{drug.brandName}</strong>
                      <p>{drug.din.value}</p>
                      <p>{drug.pharmaceuticalForm}</p>
                      <p>
                        {combineDrugIngredientsIntoValue(
                          drug.activeIngredients
                        )}
                      </p>
                    </div>
                    <CheckCircleIcon style={{ color: '66CD00' }} />
                    <div className="close">
                      <CloseIcon onClick={() => onRemoveItem(drug)} />
                    </div>
                  </SelectedDrug>
                )
              })
            ) : (
              <EmptyStateText>
                Select DINs from the search results to add to the program
              </EmptyStateText>
            )}
          </SearchResults>
        ) : null}
      </SearchSection>
    </form>
  )
}

export default DrugSearch
