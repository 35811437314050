import { FC, PropsWithChildren, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { CellProps } from 'react-table'

import { COLORS, FONTS } from 'src/shared/theme'
import Table from 'src/shared/views/Table/Table'
import {
  DetailsContainer,
  DetailsHeaderContainer,
  DetailsHeader
} from 'src/shared/styled/Layout'
import { HeaderName, SubHeaderName } from 'src/shared/styled/Text'
import { useUserFromQueryString } from '../hooks/useUserFromQueryString'
import { IUserActivities } from 'src/models/user'
import { TRANSACTION_DATE_TIME_FORMAT } from 'src/constants/datetimes'
import { momentFormat } from 'src/utils/dates'

type ActivityProp = {
  data: IUserActivities[]
}

const Activity: FC<ActivityProp> = ({ data }) => {
  const user = useUserFromQueryString()
  const columns = useMemo(
    () => [
      {
        Header: 'Date & Time',
        accessor: 'performedAt',
        Cell: ({ row }: PropsWithChildren<CellProps<IUserActivities, any>>) =>
          momentFormat(
            new Date(row.original.performedAt),
            TRANSACTION_DATE_TIME_FORMAT
          )
      },
      {
        Header: 'Activity Type',
        accessor: 'type',
        disableSortBy: true
      },
      {
        Header: 'Activity By',
        accessor: 'performedBy',
        disableSortBy: true
      },
      {
        Header: 'Activity Details',
        accessor: 'details',
        disableSortBy: true
      }
    ],
    []
  )

  return (
    <>
      <DetailsContainer>
        <DetailsHeaderContainer>
          <DetailsHeader>
            <div>
              <HeaderName style={{ textTransform: 'uppercase' }}>
                Users /{' '}
                {user?.firstName && (
                  <strong style={{ fontFamily: FONTS.bold }}>
                    {user.firstName} {user.lastName}
                  </strong>
                )}
              </HeaderName>
              <SubHeaderName>Activity Feed</SubHeaderName>
            </div>
          </DetailsHeader>
        </DetailsHeaderContainer>
      </DetailsContainer>
      <Box p={3}>
        <Table
          columns={columns}
          data={data}
          placeholder="All activity types"
          emptyMessage="There are no activities for this program."
          getHeaderProps={() => ({
            style: {
              background: COLORS.white
            }
          })}
        />
      </Box>
    </>
  )
}

export default Activity
