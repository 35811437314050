import { FC, PropsWithChildren, useMemo } from 'react'
import { CellProps } from 'react-table'

import { TRANSACTION_DATE_TIME_FORMAT } from 'src/constants/datetimes'
import { IRefillTransaction } from 'src/models/transaction'
import { COLORS } from 'src/shared/theme'
import Table from 'src/shared/views/Table/Table'
import { momentFormat } from 'src/utils/dates'

type RefillsProps = {
  data: IRefillTransaction[]
}

const Refills: FC<RefillsProps> = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Transaction Date',
        accessor: 'performedAt',
        Cell: ({ row }: CellProps<IRefillTransaction, any>) =>
          momentFormat(
            new Date(row.original.performedAt),
            TRANSACTION_DATE_TIME_FORMAT
          )
      },
      {
        Header: 'Transaction Type',
        disableSortBy: true,
        Cell: () => `Debit`
      },
      {
        Header: 'Points',
        accessor: 'points',
        disableSortBy: true
      },
      {
        Header: 'Value',
        accessor: 'value.amount',
        disableSortBy: true,
        Cell: ({
          row
        }: PropsWithChildren<CellProps<IRefillTransaction, any>>) =>
          `$${row.original.value.amount.toFixed(2)}`
      }
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      placeholder="Filter"
      emptyMessage="There are no refills for this program."
      getHeaderProps={() => ({
        style: {
          background: COLORS.white
        }
      })}
    />
  )
}

export default Refills
