import { FC, PropsWithChildren, useMemo, useState } from 'react'
import styled from 'styled-components'
import { CellProps, Column } from 'react-table'
import { UseMutationResult } from 'react-query'

import { TinyButton } from 'src/shared/styled/Buttons'
import { DetailsContent } from 'src/shared/styled/Layout'
import { BoldHeading } from 'src/shared/styled/Text'
import { IUserNoteBody, IUserNoteGet } from 'src/models/user'
import TextField from 'src/shared/views/TextField'
import Table from 'src/shared/views/Table/Table'
import { momentFormat } from 'src/utils/dates'
import { BACKEND_DATE_FORMAT } from 'src/constants/datetimes'

interface IUserNotesView {
  notes: IUserNoteGet[]
  createNoteMutation: UseMutationResult<any, unknown, IUserNoteBody, unknown>
}

const NotesButtonWrapper = styled.div`
  justify-content: flex-end;
  margin-top: 20px;
  display: flex;
`

const UserNotesView: FC<IUserNotesView> = ({ createNoteMutation, notes }) => {
  const [notesValue, setNotesValue] = useState('')
  const { isLoading, isError, mutateAsync } = createNoteMutation

  const onSubmit = async () => {
    await mutateAsync({ text: notesValue, postedAt: new Date().toISOString() })
    setNotesValue('')
  }

  const columns: Column[] = useMemo(
    () => [
      {
        Header: 'Date Posted',
        accessor: 'postedAt',
        width: 40,
        Cell: ({ row }: PropsWithChildren<CellProps<IUserNoteGet, any>>) =>
          momentFormat(new Date(row.original.postedAt), BACKEND_DATE_FORMAT)
      },
      {
        Header: 'Admin',
        accessor: 'addedByAdmin.name',
        disableSortBy: true,
        width: 40
      },
      {
        Header: 'Note',
        accessor: 'text',
        disableSortBy: true
      }
    ],
    []
  )

  notes.sort(
    (a, b) => new Date(b.postedAt).getTime() - new Date(a.postedAt).getTime()
  )

  return (
    <DetailsContent>
      <BoldHeading>Notes</BoldHeading>
      <TextField
        style={{ marginTop: '20px', width: '100%' }}
        rows={2}
        multiline
        label="Add a new note"
        value={notesValue}
        onChange={(e): void => setNotesValue(e.target.value)}
      />
      <NotesButtonWrapper>
        <TinyButton
          disabled={!notesValue}
          variant="outlined"
          onClick={() => setNotesValue('')}
        >
          Cancel
        </TinyButton>
        <TinyButton
          isLoading={isLoading}
          disabled={!notesValue}
          onClick={onSubmit}
        >
          Save Note
        </TinyButton>
      </NotesButtonWrapper>
      {isError && <p>There was an error creating this note</p>}
      <Table columns={columns} data={notes} emptyMessage="No notes yet" />
    </DetailsContent>
  )
}

export default UserNotesView
