import Select, {
  ActionMeta,
  GroupBase,
  SingleValue,
  StylesConfig
} from 'react-select'

export interface ISelectItem {
  label: string
  value: string
}

type ISearchSelect = {
  options: ISelectItem[]
  label?: string
  select?: boolean
  styles?: StylesConfig<ISelectItem, false, GroupBase<ISelectItem>>
  SelectProps?: any
  disabled?: boolean
  onChange: (
    newValue: SingleValue<ISelectItem>,
    actionMeta: ActionMeta<ISelectItem>
  ) => void
}

const SearchSelect = ({
  options,
  onChange,
  label,
  styles,
  disabled
}: ISearchSelect) => {
  return (
    <div>
      <span>{label}</span>
      <Select
        options={options}
        onChange={onChange}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          ...styles
        }}
        isClearable
      />
    </div>
  )
}

export default SearchSelect
