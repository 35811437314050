import { VerificationTaskStatus } from 'src/models/verificationTask'

export const FETCH_ALL_PROGRAMS = '/programs'
export const FETCH_PROGRAM = (programId: string) => ['/program', programId]
export const FETCH_SEARCHED_PROGRAM = (value: string) => [
  '/program-search',
  value
]
export const FETCH_PROGRAM_STRATEGIES = (programId: string) => [
  '/program-strategies',
  programId
]
export const FETCH_PROGRAM_WAIT_LIST = (programId: string) => [
  '/program-wait-list',
  programId
]

export const FETCH_ALL_USERS = '/user'
export const FETCH_ALL_DINS = '/dins'
export const FETCH_DIN = (din: string) => ['/din', din]
export const FETCH_SEARCHED_DRUGS = '/drug-search'
export const FETCH_SEARCHED_DRUG = (value: string) => ['/drug-search', value]
export const FETCH_USER = (userId: string) => ['/users', userId]
export const FETCH_USER_BALANCES = (userId: string) => [
  '/user-balances',
  userId
]
export const FETCH_USER_PRESCRIPTIONS = (userId: string) => [
  '/user-prescriptions',
  userId
]
export const FETCH_USER_PRESCRIPTION_COMPATIBLE_PROGRAMS = (
  userId: string,
  prescriptionId: string
) => ['/user-prescription-compatible-programs', userId, prescriptionId]
export const FETCH_TASKS_USER_PROGRAM = (userId: string, programId: string) => [
  '/tasks-user-program',
  userId,
  programId
]

export const FETCH_USER_NOTIFICATIONS = (userId: string) => [
  '/notifications',
  userId
]
export const FETCH_USER_NOTIFICATION_SETTINGS = (userId: string) => [
  '/notifications-settings',
  userId
]

export const FETCH_USER_NOTES = (userId: string) => ['/user-notes', userId]
export const FETCH_USER_PROGRAMS = (userId: string) => [
  '/user-programs',
  userId
]
export const FETCH_USER_ACTIVITIES = (userId: string) => [
  '/user-activities',
  userId
]
export const FETCH_USER_TRANSACTIONS = (userId: string) => [
  '/user-transactions',
  userId
]
export const FETCH_USER_TRANSACTIONS_FOR_PROGRAM = (
  userId: string,
  programId: string
) => ['/user-transactions-for-program', userId, programId]
export const FETCH_USER_PROGRAM_CERTIFICATE = (
  userId: string,
  programId: string
) => ['/user-program-certification', userId, programId]
export const FETCH_USER_DIRECT_DEPOSITS = (userId: string) => [
  '/user-direct-deposits',
  userId
]
export const FETCH_USER_E_TRANSFERS = (userId: string) => [
  '/user-e-transfers',
  userId
]
export const FETCH_USER_CLAIMS = (userId: string) => ['/user-claims', userId]
export const FETCH_USER_LEARNING_MODULE_RESULTS = (
  userId: string,
  programId: string,
  learningModuleId: string
) => ['/user-learning-module-results', userId, programId, learningModuleId]
export const FETCH_SEARCHED_INDICATIONS = (name: string) => [
  '/indication-search',
  name
]
export const FETCH_SEARCHED_SIDE_EFFECTS = (name: string) => [
  '/side-effect-search',
  name
]
export const FETCH_INDICATION = (indicationId: string) => [
  '/indication',
  indicationId
]
export const FETCH_SIDE_EFFECT = (sideEffectId: string) => [
  '/side-effect',
  sideEffectId
]
export const FETCH_ALL_LEARNING_MODULES = '/learning-modules'
export const FETCH_LEARNING_MODULE = (id: string) => ['/learning-module', id]
export const FETCH_ATTACHED_LEARNING_MODULES = (programId: string) => [
  '/attached-learning-modules',
  programId
]
export const FETCH_WEEKLY_PROGRAM_LEARNING_POINT_ALLOCATION = (
  programId: string
) => ['/weekly-program-learning-point-allocation', programId]

export const FETCH_GET_IMAGE_URL = (imageKey: string) => [
  '/image-get-presigned-url',
  imageKey
]

export const FETCH_PUT_IMAGE_URL = (imageKey: string) => [
  '/image-put-presigned-url',
  imageKey
]

export const FETCH_VERIFICATION_TASKS = (
  status: VerificationTaskStatus,
  offset: number,
  limit: number,
  dateAfter?: Date,
  userId?: string
) => [
  '/verification-tasks',
  status,
  offset,
  limit,
  dateAfter?.toISOString(),
  userId
]

export const FETCH_VERIFICATION_TASK_AUDIT_LOGS = (
  userId: string,
  programId: string
) => ['/verification-task-audit-logs', userId, programId]

export const FETCH_ALL_CLAIMS = '/claims'

export const FETCH_BATCH_PAYMENT_FILES = '/batch-payment-files'
export const FETCH_COMMERCIAL_CODES = '/commercial-codes'
