import React from 'react'
import { useHistory } from 'react-router-dom'
import { Create, GetApp } from '@material-ui/icons'

import { Learning, Text } from 'src/shared/styled'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import Table from '../../../shared/views/Table/Table'
import { COLORS } from 'src/shared/theme'
import { useLearningModules } from 'src/shared/hooks/learningModules'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import { UserPermissionsEnum } from 'src/models/user'
import { usePermissions } from 'src/shared/hooks/usePermissions'
import { Column } from 'react-table'

const { LearningModulesContainer, HeaderContainer, ViewLink, ActionItems } =
  Learning
const { PageLevelHeading } = Text

const LearningModules = () => {
  const { learningModules, isLoading } = useLearningModules()
  const permissions = usePermissions()
  const history = useHistory()

  const columns: Column[] = React.useMemo(
    () => [
      {
        Header: 'Learning Module Title',
        accessor: 'title'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Time to Complete',
        accessor: 'estimatedDuration',
        Cell: ({ row }: any) => {
          const { estimatedDuration } = row.original
          return (
            <>
              {`${estimatedDuration} ${
                estimatedDuration > 1 ? 'mins' : 'min'
              } `}
            </>
          )
        }
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Details',
        accessor: 'details',
        Cell: ({ row }: any) => (
          <ActionItems>
            <ViewLink to={`/learning/edit/${row.original.learningModuleId}`}>
              <Create />
            </ViewLink>
            <a
              className="download-link"
              href={
                'data:text/json;charset=utf-8,' +
                encodeURIComponent(JSON.stringify(row.original))
              }
              download={`${row.original.title} - ${row.original.learningModuleId}.json`}
            >
              <GetApp />
            </a>
          </ActionItems>
        ),
        disableSortBy: true,
        width: 60
      }
    ],
    []
  )

  return (
    <LearningModulesContainer>
      <HeaderContainer>
        <PageLevelHeading>Learning Management</PageLevelHeading>
        {permissions.includes(UserPermissionsEnum.LearningModuleWrite) && (
          <div>
            <ButtonWithMargin onClick={() => history.push('/learning/create')}>
              CREATE LEARNING MODULE
            </ButtonWithMargin>
          </div>
        )}
      </HeaderContainer>
      <div className="table-container">
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <Table
            columns={columns}
            data={learningModules}
            placeholder="Search Learning Modules"
            emptyMessage="No Learning Modules found"
            getHeaderProps={() => ({
              style: {
                background: COLORS.white
              }
            })}
          />
        )}
      </div>
    </LearningModulesContainer>
  )
}

export default LearningModules
