import { PersonOutlined, Warning } from '@material-ui/icons'
import React, { ChangeEvent, FC, useState } from 'react'

import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import {
  DetailsContainer,
  DetailsHeader,
  DetailsHeaderContainer
} from 'src/shared/styled/Layout'
import { HeaderName, SubHeaderName } from 'src/shared/styled/Text'
import { IUser, UserPermissionsEnum, UserStatusEnum } from 'src/models/user'
import { useUserInfoForm } from 'src/features/userAdmin/hooks/useUserInfoForm'
import { FONTS } from 'src/shared/theme'
import Tab, { TabChildren } from 'src/shared/views/Navigation/TabNavigation'
import UserInfoAccountForm from './UserInfoAccountForm'
import { USER_INFO_TAB_OPTIONS } from 'src/constants/user'
import Modal from 'src/shared/views/Modal'
import UserInfoMedicationHistory from '../containers/UserInfoMedicationHistory'

import BlockUser from 'src/features/userAdmin/containers/BlockUser'
import UnblockUser from 'src/features/userAdmin/containers/UnblockUser'
import AnonymizeUser from 'src/features/userAdmin/containers/AnonymizeUser'
import { UseMutationResult } from 'react-query'
import { usePermissions } from 'src/shared/hooks/usePermissions'
import PrescriptionForms from '../containers/PrescriptionForms'
import DirectDeposit from '../containers/DirectDeposit'
import Claims from '../containers/Claims'
import UserTransactions from '../containers/UserTransactions'
import styled from 'styled-components'
import moment from 'moment'
import PurgeUserContainer from '../containers/PurgeUser'

const getUserPhoneNumbersDropDownValues = (user: IUser) => {
  let phoneNumberDropDownValues = [{ value: 'None', label: 'No Phone Number' }]

  let phoneNumbers: string[] = []
  if (user?.phoneNumbers && user?.phoneNumbers?.length > 0) {
    phoneNumbers = user.phoneNumbers
  } else if (user?.phoneNumber) {
    phoneNumbers = [user.phoneNumber]
  }

  if (phoneNumbers?.length > 0) {
    phoneNumberDropDownValues = phoneNumbers.map((p, i) => ({
      value: p,
      label: i > 0 ? `${p} (old)` : p
    }))
  }
  return phoneNumberDropDownValues
}

interface IUserInfoView {
  userId: string | null
  user: IUser
  updateUserMutation: UseMutationResult<IUser, unknown, IUser, unknown>
}

enum ModalTypeEnum {
  Block = 'Block',
  Unblock = 'Unblock',
  Anonymize = 'Anonymize',
  AddMedication = 'AddMedication',
  Purge = 'Purge'
}

const RedTab = styled.div`
  background-color: #ef4444;
  display: inline-block;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 1rem;
  font-weight: bold;
`

const UserInfo: FC<IUserInfoView> = ({ user, updateUserMutation }) => {
  /**
   * ----- Hook Initialization -----
   */

  const permissions = usePermissions()
  const { control, formState, handleSubmit, watch, setValue } = useUserInfoForm(
    {
      defaultValues: { ...user }
    }
  )

  const { isLoading, isError, mutate } = updateUserMutation

  watch()

  const [currentTab, setCurrentTab] = useState(0)
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalType, setModalType] = useState<ModalTypeEnum>()

  /**
   * ----- Variables -----
   */

  const phoneNumberDropDownValues = getUserPhoneNumbersDropDownValues(user)

  const tabPanelOptions = [
    <UserInfoAccountForm
      setValue={setValue}
      errors={formState.errors}
      control={control}
      phoneNumberDropDownValues={phoneNumberDropDownValues}
      addressDefaultValues={user.address}
      user={user}
    />,
    <UserInfoMedicationHistory />,
    <UserTransactions user={user} />,
    <DirectDeposit />,
    <Claims />
  ]

  const isUserUnder18 = React.useMemo(() => {
    const userBirthDate = new Date(user.dob)
    const difference = moment().diff(userBirthDate, 'years')

    return difference < 18
  }, [user.dob])

  /**
   * ----- Functions -----
   */

  const handleTabChange = (
    _event: ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    setCurrentTab(newValue)
  }

  const onSubmit = (values: IUser) => {
    mutate(values)
  }

  const showModal = (type: ModalTypeEnum) => {
    setModalType(type)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setModalType(undefined)
  }

  /**
   * ----- Render -----
   */

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        handleClose={closeModal}
        fullWidth={modalType === ModalTypeEnum.AddMedication}
      >
        {modalType === ModalTypeEnum.Block && (
          <BlockUser closeModal={closeModal} />
        )}
        {modalType === ModalTypeEnum.Unblock && (
          <UnblockUser closeModal={closeModal} />
        )}
        {modalType === ModalTypeEnum.Anonymize && (
          <AnonymizeUser closeModal={closeModal} />
        )}
        {modalType === ModalTypeEnum.AddMedication && (
          <PrescriptionForms closeModal={closeModal} />
        )}
        {modalType === ModalTypeEnum.Purge && (
          <PurgeUserContainer closeModal={closeModal} />
        )}
      </Modal>

      <DetailsContainer>
        <DetailsHeaderContainer>
          <DetailsHeader>
            <div>
              <HeaderName style={{ textTransform: 'uppercase' }}>
                Users /{' '}
                <strong style={{ fontFamily: FONTS.bold }}>
                  {user.firstName} {user.lastName}
                </strong>
              </HeaderName>
              <SubHeaderName>User Information</SubHeaderName>
              <div>{isUserUnder18 && <RedTab>Under 18</RedTab>}</div>
            </div>
            {isError && <p>There was an error updating this user</p>}
            {permissions.includes(UserPermissionsEnum.UserWrite) && (
              <div>
                {currentTab === 0 && (
                  <>
                    {user.status === UserStatusEnum.Blocked ? (
                      <ButtonWithMargin
                        variant="outlined"
                        color="primary"
                        onClick={() => showModal(ModalTypeEnum.Unblock)}
                      >
                        Unblock User
                      </ButtonWithMargin>
                    ) : (
                      <ButtonWithMargin
                        variant="outlined"
                        color="primary"
                        onClick={() => showModal(ModalTypeEnum.Block)}
                      >
                        Block User
                      </ButtonWithMargin>
                    )}

                    <ButtonWithMargin
                      isLoading={isLoading}
                      onClick={handleSubmit(onSubmit)}
                      color="secondary"
                      disabled={!formState.isDirty}
                    >
                      Update User
                    </ButtonWithMargin>
                    <ButtonWithMargin
                      variant="contained"
                      color="primary"
                      onClick={() => showModal(ModalTypeEnum.Anonymize)}
                      startIcon={<PersonOutlined />}
                    >
                      Anonymize User
                    </ButtonWithMargin>
                    <ButtonWithMargin
                      variant="contained"
                      color="default"
                      onClick={() => showModal(ModalTypeEnum.Purge)}
                      startIcon={<Warning />}
                    >
                      Purge User
                    </ButtonWithMargin>
                  </>
                )}
                {currentTab === 1 && (
                  <ButtonWithMargin
                    variant="contained"
                    color="primary"
                    onClick={() => showModal(ModalTypeEnum.AddMedication)}
                  >
                    Add New Medication
                  </ButtonWithMargin>
                )}
              </div>
            )}
          </DetailsHeader>
          <Tab
            value={currentTab}
            onChange={handleTabChange}
            tabOptions={USER_INFO_TAB_OPTIONS}
            aria-label="user info"
          />
        </DetailsHeaderContainer>
        <TabChildren
          type="userInfo"
          value={currentTab}
          tabPanelOptions={tabPanelOptions}
        />
      </DetailsContainer>
    </>
  )
}

export default UserInfo
