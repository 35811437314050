import React from 'react'
import { IPrescription } from 'src/models/prescription'
import { UserPermissionsEnum } from 'src/models/user'
import { usePermissions } from 'src/shared/hooks/usePermissions'

import { DetailsContent } from 'src/shared/styled/Layout'
import { Heading } from 'src/shared/styled/Text'
import Tab, { TabChildren } from 'src/shared/views/Navigation/TabNavigation'
import styled from 'styled-components'
import PrescriptionDosageForm from '../views/PrescriptionDosageForm'
import PrescriptionGeneralForm from '../views/PrescriptionGeneralForm'
import PrescriptionRefillForm from '../views/PrescriptionRefillForm'
import PrescriptionCreationForm from '../views/PrescriptionCreationForm'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { Delete } from '@material-ui/icons'
import { useDeletePrescription } from 'src/shared/hooks/prescription'
import { useParams } from 'react-router'
import { UserAdminMatchParams } from 'src/models/routeParams'
import { toast } from 'react-toastify'
import PrescriptionProgramMembership from '../views/PrescriptionProgramMembership'

interface IPrescriptionModal {
  prescription?: IPrescription
  closeModal?: () => void
}

const PrescriptionFormContainer = styled(DetailsContent)`
  height: 80vh;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const PrescriptionForms: React.FC<IPrescriptionModal> = ({
  prescription: presc,
  closeModal
}) => {
  const { id: userId } = useParams<UserAdminMatchParams>()

  const { deletePrescription, isLoading, isError, isSuccess, reset } =
    useDeletePrescription(userId)

  const [prescription, setPrescription] = React.useState(presc)
  const [currentTab, setCurrentTab] = React.useState(0)

  const permissions = usePermissions()

  const canCurrentUserWrite = permissions.includes(
    UserPermissionsEnum.UserWrite
  )

  const handleTabChange = (_event: any, newValue: number) => {
    setCurrentTab(newValue)
  }

  const removePrescription = () => {
    if (prescription && window.confirm('Are you sure?')) {
      deletePrescription(prescription.prescriptionId)
    }
  }

  const tabOptions = React.useMemo(() => {
    if (prescription)
      return [
        {
          label: 'GENERAL',
          type: 'userPrescriptionGeneral'
        },
        {
          label: 'DOSAGE',
          type: 'userPrescriptionDosage'
        },
        {
          label: 'REFILL',
          type: 'userPrescriptionRefill'
        },
        {
          label: 'PROGRAM',
          type: 'prescriptionProgramMembership'
        }
      ]
    else return []
  }, [prescription])

  const tabPanelOptions = React.useMemo(() => {
    if (prescription)
      return [
        <PrescriptionGeneralForm
          prescription={prescription}
          canCurrentUserWrite={canCurrentUserWrite}
          setPrescription={setPrescription}
        />,
        <PrescriptionDosageForm
          prescription={prescription}
          canCurrentUserWrite={canCurrentUserWrite}
          setPrescription={setPrescription}
        />,
        <PrescriptionRefillForm
          prescription={prescription}
          canCurrentUserWrite={canCurrentUserWrite}
          setPrescription={setPrescription}
        />,
        <PrescriptionProgramMembership
          prescription={prescription}
          canCurrentUserWrite={canCurrentUserWrite}
          setPrescription={setPrescription}
        />
      ]
    else return []
  }, [prescription, canCurrentUserWrite])

  React.useEffect(() => {
    if (!isLoading && isSuccess) {
      reset()
      toast.success('Prescription successfully deleted')
      setPrescription(undefined)
      if (closeModal) closeModal()
    } else if (!isLoading && isError) {
      reset()
      toast.error(
        'Unable to delete prescription, please try again or contact support'
      )
    }
  }, [isSuccess, isError, isLoading, closeModal, reset])

  return (
    <PrescriptionFormContainer>
      <HeaderContainer>
        <Heading>{prescription ? 'Update' : 'Create'} Prescription</Heading>
        {prescription && (
          <ButtonWithWidth
            variant="outlined"
            color="primary"
            startIcon={<Delete />}
            onClick={removePrescription}
            isLoading={isLoading}
          >
            Delete
          </ButtonWithWidth>
        )}
      </HeaderContainer>
      {prescription ? (
        // HANDLE UPDATE
        <>
          <Tab
            value={currentTab}
            onChange={handleTabChange}
            tabOptions={tabOptions}
            aria-label="prescription form"
          />
          <TabChildren
            tabPanelOptions={tabPanelOptions}
            type="prescriptionForm"
            value={currentTab}
          />
        </>
      ) : (
        // HANDLE CREATION
        <PrescriptionCreationForm
          canCurrentUserWrite={canCurrentUserWrite}
          setPrescription={setPrescription}
        />
      )}
    </PrescriptionFormContainer>
  )
}

export default PrescriptionForms
