import React from 'react'

import { IProgramCreateData } from 'src/models/program'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useCreateProgram } from 'src/shared/hooks/program'
import { uploadImage } from 'src/api/services/amplify'
import CreateProgramInfo from '../views/CreateProgramInfo'

const CreateProgramContainer = () => {
  /**
   * ----- Hook Initialization -----
   */

  const history = useHistory()

  const { create: createProgram } = useCreateProgram({
    onSuccess: (data) => {
      console.log(data)
      history.push(`/programs/${data.data}/details`)
    },
    onError: () => {
      toast.warn('Error creating program - ensure name is unique')
    }
  })

  /**
   * ----- Functions -----
   */

  const handleSubmit = React.useCallback(
    async (data: IProgramCreateData) => {
      const entryPointCopy = JSON.parse(JSON.stringify(data.entryPoint))
      if (entryPointCopy.imageUrl !== undefined) {
        const uploadedImageUrl = await uploadImage(
          entryPointCopy.imageUrl || '',
          'access-code'
        )

        if (uploadedImageUrl) {
          entryPointCopy.imageUrl = uploadedImageUrl
        } else {
          entryPointCopy.imageUrl = undefined
        }
      }

      createProgram({
        ...data,
        entryPoint: entryPointCopy
      })
    },
    [createProgram]
  )

  /**
   * ----- Render -----
   */

  return <CreateProgramInfo handleCreateSubmit={handleSubmit} />
}

export default CreateProgramContainer
