import React from 'react'
import { useParams } from 'react-router-dom'

import { COLORS } from 'src/shared/theme'
import Table from 'src/shared/views/Table/Table'
import { Program, Tools } from 'src/shared/styled'
import { DropDown } from 'src/shared/views/DropDown'
import {
  useAttachedLearningModules,
  useUnlinkLearningModule
} from 'src/shared/hooks/learningModules'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import Modal from 'src/shared/views/Modal'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import styled from 'styled-components'

const { LearningModulesContainer } = Program
const { ViewModalLink } = Tools

interface IParams {
  id: string
}
interface ILearningModulesProps {
  programDuration: number
}
const dropdownStyles = {
  marginBottom: '30px',
  marginRight: '20px',
  minWidth: '200px',
  width: '50%',
  maxWidth: '250px'
}

const TextContainer = styled.div`
  margin-bottom: 2em;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
`

const DontShowAgainContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 1em;
  margin-right: 1em;
`

const STORAGE_KEY = 'showLMUnlinkModal'

const LearningModules: React.FC<ILearningModulesProps> = ({
  programDuration
}) => {
  /**
   * ----- Hook Initialization -----
   */

  const { id } = useParams<IParams>()

  const { attachedModules, isLoading } = useAttachedLearningModules(id)
  const { unlink, isLoading: unlinkLoading } = useUnlinkLearningModule(id)

  const [weekViewing, setWeekViewing] = React.useState('all')
  const [modalUnlinkView, setModalUnlinkView] = React.useState<string>()
  const [understandCheck, setUnderstandCheck] = React.useState(false)
  const [shouldShowModal, setShouldShowModal] = React.useState(true)

  /**
   * ----- Variables -----
   */

  const populatedWeekList = [
    { label: 'Show All', value: 'all' },
    ...[...Array(programDuration)].map((_, index) => ({
      label: `Week ${index + 1}`,
      value: `${index + 1}`
    }))
  ]

  /**
   * ----- Functions -----
   */

  const handleOpen = React.useCallback(
    (moduleId: string) => {
      if (shouldShowModal) {
        setModalUnlinkView(moduleId)
      } else {
        unlink({
          moduleId
        })
      }
    },
    [shouldShowModal, unlink]
  )

  const handleClose = () => {
    setModalUnlinkView(undefined)
    setUnderstandCheck(false)
  }

  const handleDontShowAgain = (value: boolean) => {
    if (value) {
      localStorage.setItem(STORAGE_KEY, new Date().toISOString())
      setShouldShowModal(false)
    } else {
      localStorage.removeItem(STORAGE_KEY)
      setShouldShowModal(true)
    }
  }

  /**
   * ----- Lifecycle -----
   */

  React.useEffect(() => {
    const storageItem = localStorage.getItem(STORAGE_KEY)
    if (!storageItem) return

    const expireyDate = new Date(storageItem)
    if (new Date() > expireyDate) {
      setShouldShowModal(false)
    }
  }, [])

  /**
   * ----- Render -----
   */

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }: any) => (
          <a href={`/learning/edit/${row.original.learningModuleId}`}>
            {row.original.title}
          </a>
        )
      },
      {
        Header: 'Time to Complete',
        Cell: ({ row }: any) => {
          return row.original.estimatedDuration
            ? `${row.original.estimatedDuration} mins`
            : 'Not Defined'
        }
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: any) => (
          <ViewModalLink
            onClick={() => handleOpen(row.original.learningModuleId)}
          >
            DELETE
          </ViewModalLink>
        )
      }
    ],
    [handleOpen]
  )
  return (
    <LearningModulesContainer>
      <DropDown
        style={dropdownStyles}
        value={weekViewing}
        label="View Learnings"
        items={populatedWeekList}
        onChange={(e) => setWeekViewing(e.target.value)}
      />
      {isLoading || unlinkLoading ? (
        <LoadingScreen />
      ) : (
        <Table
          columns={columns}
          data={attachedModules}
          emptyMessage="There are no learning modules this week"
          getHeaderProps={() => ({
            style: {
              background: COLORS.white
            }
          })}
        />
      )}
      <Modal
        isOpen={!!modalUnlinkView}
        handleClose={handleClose}
        title="Delete Learning Module"
      >
        {modalUnlinkView && (
          <div>
            <TextContainer>
              <Typography>
                Are you sure you wish to delete this learning module?
              </Typography>
              <Typography style={{ fontWeight: 'bolder', marginTop: '0.5em' }}>
                Removing this learning module will delete all corresponding
                learning activities and its related data from any users who have
                been assigned this module.
              </Typography>
              <FormControlLabel
                label="I Understand"
                control={
                  <Checkbox
                    checked={understandCheck}
                    onChange={(e) => setUnderstandCheck(e.target.checked)}
                  />
                }
              ></FormControlLabel>
            </TextContainer>
            <ButtonContainer>
              <div></div>
              <div>
                <ButtonWithWidth variant="outlined" onClick={handleClose}>
                  Cancel
                </ButtonWithWidth>
                <ButtonWithWidth
                  disabled={!understandCheck}
                  onClick={() => {
                    unlink({
                      moduleId: modalUnlinkView
                    })
                    handleClose()
                  }}
                >
                  Delete
                </ButtonWithWidth>
              </div>
            </ButtonContainer>
            <DontShowAgainContainer>
              <FormControlLabel
                label="Don't show again"
                disabled={!understandCheck}
                control={
                  <Checkbox
                    checked={!shouldShowModal}
                    onChange={(e) => handleDontShowAgain(e.target.checked)}
                  />
                }
              ></FormControlLabel>
            </DontShowAgainContainer>
          </div>
        )}
      </Modal>
    </LearningModulesContainer>
  )
}

export default LearningModules
