import moment from 'moment'
import React from 'react'
import { useQueryClient } from 'react-query'
import { downloadImage, uploadImage } from 'src/api/services/amplify'
import {
  IPrescriptionVerificationTask,
  PrescriptionVerificationStatuses
} from 'src/models/verificationTask'
import { useUpdatePrescriptionVerificationImage } from 'src/shared/hooks/prescription'
import { useUpdatePrescriptionVerification } from 'src/shared/hooks/verifications'
import {
  ButtonWithMargin,
  ButtonWithWidth,
  SmallButton
} from 'src/shared/styled/Buttons'
import { COLORS } from 'src/shared/theme'
import ImageDisplay from 'src/shared/views/ImageDisplay'
import { ImageUploader } from 'src/shared/views/ImageUploader'
import ThemedTextField from 'src/shared/views/TextField'
import styled from 'styled-components'

const PrescriptionContainer = styled.div`
  margin: 15px 0;
  background-color: #e2e8f0;
  border: 1px solid #94a3b8;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  max-height: 35rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
`

const PrescriptionContent = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PrescriptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const PrescriptionFooter = styled(PrescriptionRow)`
  flex-direction: column;
  margin-top: 20px;
`

const PrescriptionFooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

const PrescriptionUpdate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
`

interface IPrescriptionVerification {
  prescription: IPrescriptionVerificationTask
  userId: string
  isRejected: boolean
  updatePrescriptionState: (prescription: IPrescriptionVerificationTask) => void
}

const PrescriptionVerification = ({
  prescription,
  userId,
  isRejected,
  updatePrescriptionState
}: IPrescriptionVerification) => {
  /**
   * ----- Hook Initialization -----
   */

  const queryClient = useQueryClient()

  const [newCustomRejectionMsg, setNewCustomRejectionMsg] = React.useState(
    prescription.verification.customRejectionMsg || ''
  )
  const [newImageUrl, setNewImageUrl] = React.useState(null as string | null)
  const [newImagePath, setNewImagePath] = React.useState(
    undefined as string | undefined
  )
  const [error, setError] = React.useState(null as string | null)

  const { isLoading, updatePrescriptionVerification } =
    useUpdatePrescriptionVerification(userId, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('/verification-tasks')

        updatePrescriptionState(data)
      }
    })

  const {
    updateImage,
    isLoading: imageLoading,
    isSuccess
  } = useUpdatePrescriptionVerificationImage(
    userId,
    prescription.prescriptionId
  )

  /**
   * ----- Functions -----
   */

  const handleStatusChange = React.useCallback(
    (status: PrescriptionVerificationStatuses) => {
      updatePrescriptionVerification({
        prescriptionId: prescription.prescriptionId,
        status,
        customRejectionMsg: newCustomRejectionMsg
      })
    },
    [
      newCustomRejectionMsg,
      prescription.prescriptionId,
      updatePrescriptionVerification
    ]
  )

  const handleImageChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const imageFile = e?.target?.files && e?.target?.files[0]

      if (!imageFile) return

      if (!['image/png', 'image/jpg', 'image/jpeg'].includes(imageFile.type)) {
        setError('File must be a png or jpeg')
      } else {
        setError(null)
        const img = URL.createObjectURL(imageFile)
        const remoteImage = await uploadImage(img, 'prescription')
        setNewImageUrl(remoteImage || null)
      }
    },
    []
  )

  const submitImageChange = React.useCallback(() => {
    if (newImageUrl) {
      updateImage(newImageUrl)
    }
  }, [newImageUrl, updateImage])

  /**
   * ----- Variables -----
   */

  const loading = isLoading

  const rejectDisabled =
    prescription.verification.status ===
      PrescriptionVerificationStatuses.Rejected || !newCustomRejectionMsg

  /**
   * ----- Life cycle -----
   */

  React.useEffect(() => {
    const getImage = async () => {
      if (newImageUrl) {
        const remoteImageUrl = await downloadImage(newImageUrl)
        if (remoteImageUrl) setNewImagePath(remoteImageUrl)
        else setNewImagePath(undefined)
      } else setNewImagePath(undefined)
    }

    getImage()
  }, [newImageUrl])

  React.useEffect(() => {
    if (isSuccess && newImageUrl) {
      updatePrescriptionState({
        ...prescription,
        verification: {
          ...prescription.verification,
          imageUrl: newImageUrl
        }
      })
      setError(null)
      setNewImageUrl(null)
    }
  }, [isSuccess, newImageUrl, prescription, updatePrescriptionState])

  /**
   * ----- Render -----
   */

  return (
    <PrescriptionContainer>
      {prescription.verification.imageUrl && (
        <ImageDisplay
          imageKey={prescription.verification.imageUrl}
          style={{ borderRadius: '5px 0 0 5px', width: '40%' }}
        />
      )}
      <PrescriptionContent>
        <PrescriptionRow>
          <div>
            <h2>
              {prescription.drugName} - {prescription.din}
            </h2>
            <h3>{prescription.doseStrength}</h3>
            <h4>
              {moment(prescription.verification.dateSubmitted).format(
                'MMMM DD YYYY hh:mma'
              )}
            </h4>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <h3>{prescription.verification.status}</h3>
            {prescription.verification.customRejectionMsg && (
              <p>
                <b>Rejection Message: </b>
                {prescription.verification.customRejectionMsg}
              </p>
            )}
          </div>
          {!isRejected && (
            <PrescriptionUpdate>
              <ImageUploader
                id="newImage"
                label="Update"
                onHandleImageChange={handleImageChange}
                onClearImage={() => setNewImageUrl(null)}
                imagePath={newImagePath}
              />
              {newImagePath && (
                <SmallButton
                  isLoading={imageLoading}
                  onClick={submitImageChange}
                >
                  Submit New Image
                </SmallButton>
              )}
            </PrescriptionUpdate>
          )}
        </PrescriptionRow>
        {!isRejected && (
          <PrescriptionFooter>
            <ThemedTextField
              label="Custom Rejection Message *"
              disabled={loading}
              variant="outlined"
              multiline
              minRows={2}
              fullWidth
              style={{
                marginTop: 10
              }}
              onChange={(e) => setNewCustomRejectionMsg(e.target.value)}
              value={newCustomRejectionMsg}
            />
            <PrescriptionFooterButtons>
              {prescription.verification.status ===
                PrescriptionVerificationStatuses.Rejected &&
              newCustomRejectionMsg !==
                prescription.verification.customRejectionMsg ? (
                <ButtonWithWidth
                  color="secondary"
                  isLoading={loading}
                  onClick={() =>
                    handleStatusChange(
                      PrescriptionVerificationStatuses.Rejected
                    )
                  }
                  tooltip={'Update rejection message'}
                >
                  Update Message
                </ButtonWithWidth>
              ) : (
                <div></div>
              )}
              <div>
                <ButtonWithMargin
                  style={{
                    backgroundColor: !rejectDisabled
                      ? COLORS.warningRed
                      : undefined
                  }}
                  color="secondary"
                  isLoading={loading}
                  onClick={() =>
                    handleStatusChange(
                      PrescriptionVerificationStatuses.Rejected
                    )
                  }
                  disabled={rejectDisabled}
                  tooltip={
                    rejectDisabled ? 'Please provide a rejection message' : ''
                  }
                >
                  Reject
                </ButtonWithMargin>
                <ButtonWithMargin
                  isLoading={loading}
                  disabled={
                    prescription.verification.status ===
                    PrescriptionVerificationStatuses.Verified
                  }
                  onClick={() =>
                    handleStatusChange(
                      PrescriptionVerificationStatuses.Verified
                    )
                  }
                >
                  Verify
                </ButtonWithMargin>
              </div>
            </PrescriptionFooterButtons>
          </PrescriptionFooter>
        )}
      </PrescriptionContent>
    </PrescriptionContainer>
  )
}

export default PrescriptionVerification
