import React from 'react'
import { Controller } from 'react-hook-form'

import Modal from '../../../shared/views/Modal'
import { Buttons, Program, Text } from 'src/shared/styled'
import {
  useAttachedLearningModules,
  useAttachLearningModule,
  useUpdateAttachedLearningModule
} from 'src/shared/hooks/learningModules'
import { IRequiredLearning } from 'src/models/program'
import { useModuleForm } from '../hooks/forms'
import { useParams } from 'react-router-dom'
import { ILMS } from 'src/models/learning'
import SearchSelect from 'src/shared/views/SearchSelect'

interface IAttachLearningModalProps {
  isOpen: boolean
  currentLearning: IRequiredLearning | null
  setAddLearningShowing: (newVal: boolean) => void
  programDuration: number
  learningModules: ILMS[]
}

interface IParams {
  id: string
}

// const formFieldStyles = {
//   marginBottom: '30px',
//   marginRight: '20px',
//   minWidth: '200px',
//   width: '50%',
//   maxWidth: '250px'
// }

const { Disclaimer } = Program
const { ButtonWithMargin } = Buttons
const { LightText } = Text

const AttachLearningModal: React.FC<IAttachLearningModalProps> = ({
  isOpen,
  currentLearning,
  setAddLearningShowing,
  learningModules
}) => {
  const { id: programId } = useParams<IParams>()
  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty }
  } = useModuleForm({
    defaultValues: {
      module: currentLearning?.learningModuleId ?? ''
    }
  })

  const {
    attach,
    isLoading: attachLoading,
    isSuccess: attachSuccess
  } = useAttachLearningModule(programId)

  const {
    update,
    isLoading: updateLoading,
    isSuccess: updateSuccess
  } = useUpdateAttachedLearningModule(programId)

  const { attachedModules, isLoading: modulesLoading } =
    useAttachedLearningModules(programId)

  const isSuccess = updateSuccess || attachSuccess
  const isLoading = updateLoading || attachLoading || modulesLoading

  const dependencyDropDownItems = learningModules?.map((learningModule) => ({
    label: learningModule.title,
    value: learningModule.learningModuleId ?? ''
  }))

  const moduleDropDownItems = dependencyDropDownItems.filter(
    (learningModule) => {
      // filter out modules that have already been attached
      return !attachedModules?.some(
        (attachedModule) =>
          attachedModule.learningModuleId === learningModule.value
      )
    }
  )

  const editingModuleDropDownItems = dependencyDropDownItems.filter(
    (learningModule) =>
      currentLearning?.learningModuleId === learningModule.value
  )

  const onSubmit = (formValues: any) => {
    if (currentLearning?.title) {
      update({ body: formValues, moduleId: formValues.module })
    } else {
      attach({ body: formValues, moduleId: formValues.module })
    }
  }

  React.useEffect(() => {
    if (isSuccess) {
      setAddLearningShowing(false)
    }
  }, [isSuccess, setAddLearningShowing])

  watch()

  return (
    <Modal
      isOpen={isOpen}
      title={currentLearning?.title || 'Add Learning Module'}
      handleClose={() => setAddLearningShowing(false)}
      dialogActions={
        <>
          <ButtonWithMargin
            type="submit"
            form="module-form"
            isLoading={isLoading}
            disabled={!isDirty}
          >
            SAVE CHANGES
          </ButtonWithMargin>
          <ButtonWithMargin
            variant="outlined"
            onClick={() => setAddLearningShowing(false)}
          >
            CANCEL
          </ButtonWithMargin>
        </>
      }
    >
      <Disclaimer>
        <LightText>
          {`By default, the weight of a learning module is set to 0%. Make sure to update and
        rebalance the weights on the "Overview" screen after adding a learning module`}
        </LightText>
      </Disclaimer>

      <form id="module-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <Controller
            name="module"
            control={control}
            render={({ field: { onChange, ...props } }) => (
              <SearchSelect
                label="Learning Module"
                options={
                  currentLearning?.learningModuleId
                    ? editingModuleDropDownItems.sort((a, b) => {
                        if (a.label >= b.label) return 1
                        else return -1
                      })
                    : moduleDropDownItems.sort((a, b) => {
                        if (a.label >= b.label) return 1
                        else return -1
                      })
                }
                disabled={!!currentLearning}
                onChange={(newValue) => newValue && onChange(newValue?.value)}
                {...props}
              />
            )}
          />
        </div>
      </form>
    </Modal>
  )
}

export default AttachLearningModal
