import React from 'react'
import { ArrowBack, Create, Delete, Publish, Undo } from '@material-ui/icons'

import { useLMS } from 'src/contexts/Learning'
import NewPage from './NewPage'
import AddLMSDetails from './AddLMSDetails'
import PreviewLearningModule from './PreviewLearningModule'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import { ILMSPage, LMSStatus } from 'src/models/learning'
import { Text, Buttons, Learning, Tools } from 'src/shared/styled'
import { useHistory } from 'react-router-dom'
import WarningNotification from 'src/shared/views/WarningNotification'
import { UserPermissionsEnum } from 'src/models/user'
import { usePermissions } from 'src/shared/hooks/usePermissions'
import { Chip as Ch, Typography } from '@material-ui/core'
import styled from 'styled-components'
import Modal from 'src/shared/views/Modal'
import { HiddenInput, ModalButtons } from 'src/shared/styled/Learning'
import { LargeIconButton } from 'src/shared/styled/Buttons'
import { ErrorText } from 'src/shared/styled/Text'

const { ButtonWithWidth } = Buttons
const { SubHeaderName, LightText } = Text
const { LearningHeader, LearningButtonColumn, LearningStatusContainer } =
  Learning
const { BoldText } = Tools

const Chip = styled(Ch)`
  margin: 0px 5px;
`

const StatusText = styled(Typography)`
  vertical-align: center;
  font-weight: bold;
  padding: 5px 5px 0 0;
`

const LearningModuleForm = () => {
  const permissions = usePermissions()
  const [showPreview, setShowPreview] = React.useState(false)

  const [fileUploadError, setFileUploadError] = React.useState('')
  const hiddenFileUploadRef = React.useRef<HTMLInputElement | null>(null)
  const {
    state,
    isInitialized,
    saveLoading,
    deleteLoading,
    draftLoading,
    publishLoading,
    isCreate,
    deleteLearningModule,
    saveLearningModule,
    resetChanges,
    updateStatus,
    uploadLearningModule
  } = useLMS()
  const [isModalOpen, setIsModalOpen] = React.useState(() => {
    if (isCreate && permissions.includes(UserPermissionsEnum.ProgramWrite))
      return true
    else return false
  })
  const history = useHistory()
  const { title, estimatedDuration, description, pages, coverImageUrl, saved } =
    state

  let renderPages: any[] = []
  if (pages.length > 0) {
    const populatedPages = pages as ILMSPage[]
    renderPages = populatedPages.map((page: ILMSPage, index: number) => (
      <NewPage key={index} page={page} pageIndex={index} />
    ))
  }

  const handleRawLearningModuleFile = (file: File) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.result) {
        const data = reader.result as string
        if (!data) {
          setFileUploadError('Error importing file')
        } else {
          uploadLearningModule(JSON.parse(data))
          setIsModalOpen(false)
        }
      }
    }
    reader.readAsText(file)
  }

  const isStatusLoading: boolean = React.useMemo(
    () => draftLoading === true || publishLoading === true,
    [draftLoading, publishLoading]
  )

  return (
    <div>
      {isInitialized ? (
        <>
          {!saved ? (
            <WarningNotification message="You have unpublished changes" />
          ) : null}
          <LearningHeader>
            <div
              className="back-button"
              onClick={() => history.push('/learning')}
            >
              <ArrowBack />
              <LightText>Back to Learning Modules</LightText>
            </div>
            <div className="title-container">
              <SubHeaderName>{title || 'Untitled'}</SubHeaderName>
              <LearningButtonColumn>
                <div>
                  {permissions.includes(UserPermissionsEnum.ProgramWrite) && (
                    <>
                      <ButtonWithWidth
                        variant="outlined"
                        onClick={() => resetChanges()}
                        disabled={saveLoading || deleteLoading || saved}
                        startIcon={<Undo />}
                      >
                        Reset
                      </ButtonWithWidth>
                      <ButtonWithWidth
                        color="secondary"
                        onClick={saveLearningModule}
                        isLoading={saveLoading}
                        disabled={saveLoading || deleteLoading || saved}
                      >
                        Save
                      </ButtonWithWidth>
                      <ButtonWithWidth
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          if (window.confirm('Are you sure?'))
                            deleteLearningModule()
                        }}
                        isLoading={deleteLoading}
                        disabled={saveLoading || deleteLoading}
                        startIcon={<Delete />}
                      >
                        Delete Learning Module
                      </ButtonWithWidth>
                    </>
                  )}
                  <ButtonWithWidth
                    variant="outlined"
                    onClick={() => setShowPreview(!showPreview)}
                  >
                    {showPreview ? 'Edit' : 'Preview'}
                  </ButtonWithWidth>
                </div>
                {!isCreate && (
                  <LearningStatusContainer>
                    <StatusText>Status: </StatusText>
                    <Chip
                      label="Draft"
                      disabled={isStatusLoading}
                      onClick={() => updateStatus(LMSStatus.DRAFT)}
                      clickable={state.status !== LMSStatus.DRAFT}
                      color={
                        state.status === LMSStatus.DRAFT ? 'primary' : undefined
                      }
                    />
                    <Chip
                      label="Publish"
                      disabled={isStatusLoading}
                      onClick={() => updateStatus(LMSStatus.PUBLISHED)}
                      clickable={state.status !== LMSStatus.PUBLISHED}
                      color={
                        state.status === LMSStatus.PUBLISHED
                          ? 'primary'
                          : undefined
                      }
                    />
                  </LearningStatusContainer>
                )}
              </LearningButtonColumn>
            </div>
          </LearningHeader>
          {showPreview ? (
            <PreviewLearningModule />
          ) : (
            <>
              <AddLMSDetails
                title={title}
                estimatedDuration={estimatedDuration}
                description={description}
                coverImageUrl={coverImageUrl}
              />
              {renderPages}
            </>
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
      <Modal
        isOpen={isModalOpen}
        title="New Learning Module"
        handleClose={() => setIsModalOpen(false)}
        lockUntilActionSelected={true}
      >
        <ModalButtons>
          <HiddenInput
            ref={hiddenFileUploadRef}
            type="file"
            accept="/*"
            id="contained-button-file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files) {
                handleRawLearningModuleFile(e.target.files[0])
              }
            }}
          />
          <LargeIconButton
            variant="outlined"
            onClick={() => hiddenFileUploadRef?.current?.click()}
          >
            <Publish />
            UPLOAD
          </LargeIconButton>
          <BoldText>OR</BoldText>
          <LargeIconButton
            variant="outlined"
            onClick={() => setIsModalOpen(false)}
          >
            <Create />
            START NEW
          </LargeIconButton>
        </ModalButtons>
        {fileUploadError ? <ErrorText>{fileUploadError}</ErrorText> : null}
      </Modal>
    </div>
  )
}

export default LearningModuleForm
