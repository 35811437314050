import React from 'react'

import { StateContext } from 'src/contexts/Program'
import { Text, Layout, Buttons } from 'src/shared/styled'
import LearningModules from '../views/LearningModules'
import { IRequiredLearning } from 'src/models/program'
import AttachLearningModal from './AttachLearningModal'
import { ILMS } from 'src/models/learning'

interface ILiteracyProps {
  programDuration: any
  learningModules: ILMS[]
}

const { DetailsContainer, DetailsHeader, DetailsHeaderContainer } = Layout
const { HeaderName, SubHeaderName } = Text
const { ButtonWithWidth } = Buttons

const Literacy: React.FC<ILiteracyProps> = ({
  programDuration,
  learningModules
}) => {
  const [addLearningShowing, setAddLearningShowing] = React.useState(false)
  const [currentLearning, setCurrentLearning] =
    React.useState<IRequiredLearning | null>(null)
  const state = React.useContext(StateContext)

  const { name: programName } = state

  return (
    <DetailsContainer>
      <DetailsHeaderContainer>
        <DetailsHeader>
          <div>
            <HeaderName>{programName}</HeaderName>
            <SubHeaderName>Literacy</SubHeaderName>
          </div>
          <ButtonWithWidth
            onClick={() => {
              setAddLearningShowing(true)
              setCurrentLearning(null)
            }}
          >
            ADD LEARNING MODULE
          </ButtonWithWidth>
        </DetailsHeader>
      </DetailsHeaderContainer>
      <LearningModules programDuration={programDuration} />
      {addLearningShowing ? (
        <AttachLearningModal
          isOpen={addLearningShowing}
          currentLearning={currentLearning}
          programDuration={programDuration}
          setAddLearningShowing={(newVal) => setAddLearningShowing(newVal)}
          learningModules={learningModules}
        />
      ) : null}
    </DetailsContainer>
  )
}

export default Literacy
