import React from 'react'
import styled from 'styled-components'
import { FormControlLabel, Switch, Typography } from '@material-ui/core'

import DrugSearch from 'src/features/userAdmin/containers/DrugSearch'
import TextField from 'src/shared/views/TextField'
import { Program } from 'src/shared/styled'
import { FONTS } from 'src/shared/theme'
import { IDrugLibraryItem } from 'src/models/drugLibrary'
import { ButtonWithWidth, SmallButton } from 'src/shared/styled/Buttons'
import { ImageUploader } from 'src/shared/views/ImageUploader'
import moment from 'moment'
import { IProgramCreateData } from 'src/models/program'
import { useProgramCreateForm } from '../hooks/forms'
import { Controller } from 'react-hook-form'

const Container = styled.div`
  margin: 5rem;
`

const MainDetailsContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`
const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-self: flex-start;
`
const StyledTypography = styled(Typography)`
  font-family: ${FONTS.main};
  color: ${(props) => props.theme.palette.common.black};
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 300;
  line-height: 19px;
`

export const AddCodeButton = styled(ButtonWithWidth)`
  font-family: ${FONTS.medium};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.34px;
  line-height: 24px;
  padding: 8px 22px;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.common.white};
  }
`

const StyledFooter = styled.div`
  background: ${(props) => props.theme.palette.primary.main};
  text-align: center;
  padding: 2em 1em 4em;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const SearchResultsContainer = styled.div``

const { StyledHeading } = Program

interface ICreateProgramInfo {
  handleCreateSubmit: (data: IProgramCreateData) => void
}

const CreateProgramInfo = ({ handleCreateSubmit }: ICreateProgramInfo) => {
  /**
   * ----- Hook Initialization -----
   */

  const [dinsToDisplay, setDinsToDisplay] = React.useState<IDrugLibraryItem[]>(
    []
  )

  const { control, setValue, handleSubmit } = useProgramCreateForm()

  /**
   * ----- Functions -----
   */

  const removeImage = React.useCallback(() => {
    setValue('entryPoint.imageUrl', '')
  }, [setValue])

  const handleImageUpload = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const imageFile = e?.target?.files && e?.target?.files[0]

      if (!imageFile) return

      if (['image/png', 'image/jpg', 'image/jpeg'].includes(imageFile.type)) {
        const img = URL.createObjectURL(imageFile)

        setValue('entryPoint.imageUrl', img)
      }
    },
    [setValue]
  )

  /**
   * ----- Lifecycle -----
   */

  React.useEffect(() => {
    setValue(
      'dins',
      dinsToDisplay.map((drug) => ({ value: drug.din.value })),
      { shouldValidate: true, shouldDirty: true }
    )
  }, [dinsToDisplay, setValue])

  /**
   * ----- Rendering -----
   */

  return (
    <Container>
      <form
        onSubmit={handleSubmit(handleCreateSubmit, (e) =>
          console.log('formError', e)
        )}
      >
        <StyledHeading>Program Details</StyledHeading>
        <MainDetailsContainer>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField id="name" label="Internal Name" {...field} />
            )}
          />
          <Controller
            name="commonName"
            control={control}
            render={({ field }) => (
              <TextField id="commonName" label="Common Name" {...field} />
            )}
          />
          <Controller
            name="funds.amount"
            control={control}
            render={({ field }) => (
              <TextField
                id="funds"
                label="Funds"
                type="number"
                inputProps={{ min: 0 }}
                {...field}
              />
            )}
          />
          <Controller
            name="endDate"
            control={control}
            render={({ field }) => (
              <TextField
                id="endDate"
                label="End Date"
                value={moment(field.value).utc().format('YYYY-MM-DD')}
                type="date"
                onChange={(e) => {
                  setValue('endDate', new Date(e.target.value))
                }}
              />
            )}
          />
          <Controller
            name="waitListEnabled"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <StyledFormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    {...field}
                  />
                }
                label={<StyledTypography>Enable Waitlist</StyledTypography>}
              />
            )}
          />
        </MainDetailsContainer>

        <StyledHeading>Entry Point</StyledHeading>
        <MainDetailsContainer>
          <Controller
            name="entryPoint.displayLabel"
            control={control}
            render={({ field }) => (
              <TextField
                id="entryPoint.displayLabel"
                label="Display Label"
                {...field}
              />
            )}
          />
          <Controller
            name="entryPoint.description"
            control={control}
            render={({ field }) => (
              <TextField
                id="entryPoint.description"
                label="Description"
                multiline
                {...field}
              />
            )}
          />
          <Controller
            name="entryPoint.imageUrl"
            control={control}
            render={({ field }) => (
              <ImageUploader
                id="imageUrl"
                label="Upload Image"
                onHandleImageChange={handleImageUpload}
                onClearImage={() => removeImage()}
                imagePath={field.value}
              />
            )}
          />
          <Controller
            name="entryPoint.promptForCode"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <StyledFormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    {...field}
                  />
                }
                label={<StyledTypography>Prompt for Code</StyledTypography>}
              />
            )}
          />
          <Controller
            name="entryPoint.visibleToPatient"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <StyledFormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    {...field}
                  />
                }
                label={<StyledTypography>Visible to Patient</StyledTypography>}
              />
            )}
          />
        </MainDetailsContainer>

        <StyledHeading>Attached DINS</StyledHeading>
        <SearchResultsContainer>
          <DrugSearch
            onItemSelected={(din) => {
              if (
                !dinsToDisplay.find((drug) => drug.din.value === din.din.value)
              ) {
                setDinsToDisplay(dinsToDisplay.concat(din))
              }
            }}
            selectedDins={[]}
            dinsToDisplay={dinsToDisplay}
            showSelectedDins={true}
            onRemoveItem={(drug) => {
              setDinsToDisplay(
                dinsToDisplay.filter((din) => din.din.value !== drug.din.value)
              )
            }}
          />
        </SearchResultsContainer>
        <StyledFooter>
          <StyledButtonContainer>
            <div />
            <SmallButton color="secondary" type="submit">
              Finish
            </SmallButton>
          </StyledButtonContainer>
        </StyledFooter>
      </form>
    </Container>
  )
}

export default CreateProgramInfo
